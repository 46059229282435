<template>
  <div class="contents" id="contents">
    <div class="inner-lg">
      <!-- Title -->
      <section class="title-section">
        <div class="title-wrap">
          <h2
            :class="{ kor: $i18n.locale === 'ko' }"
          >{{$t('content.event.EventReservation.txt67')}}</h2>
          <span class="title-desc">{{$t('content.event.EventReservation.txt68')}}</span>
        </div>
      </section>
      <!-- // Title -->
      <!-- Calendar Section -->
      <section class="calendar-section">
        <div class="calendar-wrap">
          <section class="schedule-section">
            <!-- Full calendar -->
            <TimeGridDayCalendar
              :viewDate="viewDate"
              :events="events"
              :resources="resources"
              :minTime="minTime"
              :maxTime="maxTime"
              :validRange="validRange"
              @eventRender="onRender"
              @change="onChange"
              @select="onSelect"
            >
              <template v-slot:header="slotProps">
                <div class="calendar-header-area">
                  <BaseButton type="prev" initial-view="true" @click="slotProps.prev">PREV</BaseButton>
                  <div class="month">
                    {{ viewRangeTitle }}
                    <datepicker
                      v-model="viewDate"
                      ref="calendar"
                      :disabledDates="disabledDates"
                      input-class="v-hidden"
                    />
                    <span class="icon-calendar" @click="openCalendar"></span>
                  </div>
                  <BaseButton type="next" @click="slotProps.next">NEXT</BaseButton>
                </div>
              </template>
              <template v-slot:adding>
                <ul class="reservation-indicator">
                  <li class="r-confirm">{{$t('content.event.EventReservation.txt69')}}</li>
                  <li class="r-waiting">{{$t('content.event.EventReservation.txt70')}}</li>
                  <li class="r-impossible">{{$t('content.event.EventReservation.txt71')}}</li>
                </ul>
              </template>
            </TimeGridDayCalendar>

            <!-- // Full calendar -->
          </section>
          <!-- // Calendar Section -->
        </div>
      </section>
      <!-- // Calendar Section -->
      <!-- 버튼 -->
      <div class="btn-wrap perspective">
        <button class="btn-ghost"
          @click="goHistory"
        >{{$t('content.event.EventReservation.txt72')}}</button>
        <button class="btn-basic"
          @click="reserve"
        >{{$t('content.event.EventReservation.txt73')}}</button>
      </div>
      <!-- // 버튼 -->
    </div>
  </div>
</template>

<script>
import { APIs } from '@/common/portalApi';
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import TimeGridDayCalendar from '@/components/calendar/TimeGridDayCalendar.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import Datepicker from '@sum.cumo/vue-datepicker';
import ValidReservation from '@/common/ValidReservation';
import '@sum.cumo/vue-datepicker/dist/vuejs-datepicker.css';

export default {
  name: 'Reservation',
  mixins: [mixinHelperUtils],
  components: {
    BaseButton,
    TimeGridDayCalendar,
    Datepicker,
  },
  
  data() {
    return {
      userInfo: undefined,
      selectedHalls: [],
      eventhalls: {
        '6': [],
        '7': [],
        '8': [],
        '9': [],
        '10': [],
        '11': [],
      },
      codeDivisionCenter: 'CT101',
      viewDate: undefined, // 실제 사용되는 시간
      activeDate: undefined, // 초기 설정을 위한 임시날짜
      queryDate: undefined, // 쿼리 스트링으로 받아오는 날짜
      isWeekend: undefined,
      minTime: '10:00:00',
      maxTime: '23:00::00',
      validRange: {
        start: this.activeDate,
        end: '2030-01-01',
      },
      resources: [
        { id: '6', title: this.$t('commonCode.6') },
        { id: '7', title: this.$t('commonCode.7') },
        { id: '8', title: this.$t('commonCode.8') },
        { id: '9', title: this.$t('commonCode.9') },
        { id: '10', title: this.$t('commonCode.10') },
        { id: '11', title: this.$t('commonCode.11') },
      ],
      events: [
        // {
        //   id: '6',
        //   resourceId: '6',
        //   // title: 'Meeting',
        //   start: '2020-07-23T11:00:00Z',
        //   end: '2020-07-23T13:00:00Z',
        //   className: 'r-waiting', // r-confirm, r-impossible
        // },
      ],
      disabledDates: undefined,
      holidays: [
        // new Date(2020, 4, 5) // 2020.5.5
      ],
    };
  },
  
  computed: {
    viewRangeTitle() {
      return this.$moment(this.viewDate).format(this.$i18n.locale === 'en' ? 'DD.MM.YYYY' : 'YYYY.MM.DD');
    },
  },
  
  watch: {
    isWeekend() {
      if (this.isWeekend) {
        this.minTime = '09:00:00';
        this.maxTime = '18:00:00';
      } else {
        this.minTime = '09:00:00';
        this.maxTime = '21:00:00';
      }
    },
    
    viewDate(newValue, oldValue) {
      // 시작 시 두번 호출 되는 오류 수정
      if (oldValue == null || newValue.toDateString() != oldValue.toDateString()) {
        console.log(newValue, oldValue, newValue != oldValue);
        this.retrieve();
      }
    },
  },
  
  created() {
    this.userInfo = this.$store.getters.getUserInfo();
    this.queryDate = this.$route.query.Date ? this.$route.query.Date : '';
    const limitDate = this.$moment().add(7, 'day');
    this.activeDate = this.$moment().add(8, 'day');
    const limitFrom = this.$moment().add(2, 'months');
    this.disabledDates = {
      to: limitDate.toDate(),
      from: limitFrom.toDate(),
      days: [],
      dates: this.holidays,
    };
    // fullcalendar 유효 범위설정
    this.validRange.start = this.activeDate.format('YYYY-MM-DD');
    this.validRange.end = limitFrom.add(1, 'day').format('YYYY-MM-DD');
  },
  mounted() {
    this.viewDate = this.queryDate ? this.$moment(this.queryDate).toDate() : this.activeDate.toDate();
    this.showAlert(this.$t('content.event.EventReservation.alertMsg.msg1'));
  },
  methods: {
    reserve() {
      const reservation = new ValidReservation(this);

      if (reservation.inValidate()) {
        this.showAlert(reservation.msgError);
      } else {
        const parmas = { rooms: [] };
        this.selectedHalls.forEach((v) => {
          parmas.rooms.push(v.resourceId);
        });
        
        parmas.startTime = this.$moment(this.selectedHalls[0].start);
        parmas.endTime = this.$moment(this.selectedHalls[0].end);

        // if (parmas.rooms.includes('10') && parmas.rooms.includes('11')) {
        //   parmas.rooms = parmas.rooms.reduce((all, v) => {
        //     if (v === '10') {
        //       v = 'CT207';
        //     }
        //     if (v === '11') {
        //       v = 'CT208';
        //     }
        //     return [...all, v];
        //   }, []);
        // }

        if (this.$moment(this.viewDate).format('YYYYMMDD') !== this.$route.query.Date) {
          this.$router.replace(`?Date=${this.$moment(this.viewDate).format('YYYYMMDD')}`);
        }
        this.$router.push({ name: 'ReservationApply', params: parmas });
      }
    },

    goHistory() {
      if (this.$moment(this.viewDate).format('YYYYMMDD') !== this.$route.query.Date) {
        this.$router.replace(`?Date=${this.$moment(this.viewDate).format('YYYYMMDD')}`);
      }
      this.$router.push({ name: 'ReservationHistory' });
    },
    resetHall() {
      this.eventhalls = {};
      this.eventhalls[6] = [];
      this.eventhalls[7] = [];
      this.eventhalls[8] = [];
      this.eventhalls[9] = [];
      this.eventhalls[10] = [];
      this.eventhalls[11] = [];
      this.selectedHalls = [];
      this.events = [];
    },
    getList() {
      const borrowDate = this.$moment(this.$data.viewDate).format('YYYY.MM.DD');
      this.portalApiClient({
        url: APIs.CENTER_GANGNAM_VENUE_LIST,
        data: {
          date1: borrowDate,
          // code_division_center: this.codeDivisionCenter,
        },
      }).then(({ data }) => {
        if (this.isWeekend) {
          for (let i = 0; i < this.resources.length; i += 1) {
            this.eventhalls[this.resources[i].id * 1].push({
              resourceId: this.resources[i].id,
              className: 'r-impossible',
              start: `${this.$moment(this.viewDate).format('YYYY-MM-DD')} 09:00:00`,
              end: `${this.$moment(this.viewDate).format('YYYY-MM-DD')} 09:30:00`,
            });
          }
        }
        console.log(this.isWeekend, this.eventhalls);
        // prevent hall
        data.list.forEach((v) => {
          const placeIds = v.reservedPlaceIds.split(',');
          let origin;
          let event;

          placeIds.forEach((placeId) => {
            if (!this.stopList.includes(placeId)) {
              if (v.memberId !== 0) {
                origin = `${this.$moment(`${borrowDate} ${v.startTime.split(' ')[1]}`)
                .format('HH:mm')} - ${this.$moment(`${borrowDate} ${v.endTime.split(' ')[1]}`).format('HH:mm')}`;
              } else {
                origin = `${this.$moment(`${borrowDate} ${v.startTime.split(' ')[1]}`).add(90, 'm').
                format('HH:mm')} - ${this.$moment(`${borrowDate} ${v.endTime.split(' ')[1]}`).add(-90, 'm').format('HH:mm')}`;
              }
              let start = this.$moment(`${borrowDate} ${v.startTime.split(' ')[1]}`).add(-30, 'm')
              let end = this.$moment(`${borrowDate} ${v.endTime.split(' ')[1]}`).add(30, 'm')
              
              event = {
                resourceId: placeId,
                start: start.format('YYYY-MM-DD HH:mm:ss'),
                end: end.format('YYYY-MM-DD HH:mm:ss'),
                className: 'r-imposible',
                // 대관신청 s
                memberId: v.memberId,
                placeId: placeId,
                status: v.reservationState,
                title: origin,
                origin,
                // 대관신청 e
              };

              if (placeId == '36') {
                this.eventhalls[8].push({
                  ...event,
                  resourceId: 8,
                  placeId: 8
                });

                this.eventhalls[9].push({
                  ...event,
                  resourceId: 9,
                  placeId: 9
                });
              } else if (placeId ==  '37') {
                this.eventhalls[10].push({
                  ...event,
                  resourceId: 10,
                  placeId: 10
                });

                this.eventhalls[11].push({
                  ...event,
                  resourceId: 11,
                  placeId: 11
                });
              } else {
                this.eventhalls[placeId].push(event);
              }
            }
          });
        });
        this.events = [
          ...this.eventhalls[6],
          ...this.eventhalls[7],
          ...this.eventhalls[8],
          ...this.eventhalls[9],
          ...this.eventhalls[10],
          ...this.eventhalls[11],
        ];
      });
    },
    retrieve() {
      console.log('retrieve')
      this.resetHall();
      const borrowDate = this.$moment(this.$data.viewDate).format('YYYY.MM.DD');
      // 대관 휴일
      this.portalApiClient({
        url: APIs.CENTER_GANGNAM_VENUE_HOLIDAY,
        data: {
          date1: borrowDate
        },
      }).then(({ data }) => {
        if (data.result) {
          this.stopList = data.list;
          this.stopList.forEach((v) => {
            if (v.placeId == '36') {
              this.eventhalls[8].push({
                resourceId: 8,
                start: `${this.$moment(this.$data.viewDate).format('YYYY-MM-DD')} 09:00:00`,
                end: `${this.$moment(this.$data.viewDate).format('YYYY-MM-DD')} 21:00:00`,
                className: 'r-impossible',
              });

              this.eventhalls[9].push({
                resourceId: 9,
                start: `${this.$moment(this.$data.viewDate).format('YYYY-MM-DD')} 09:00:00`,
                end: `${this.$moment(this.$data.viewDate).format('YYYY-MM-DD')} 21:00:00`,
                className: 'r-impossible',
              });

            } else if (v.placeId == '37') {
              this.eventhalls[10].push({
                resourceId: 10,
                start: `${this.$moment(this.$data.viewDate).format('YYYY-MM-DD')} 09:00:00`,
                end: `${this.$moment(this.$data.viewDate).format('YYYY-MM-DD')} 21:00:00`,
                className: 'r-impossible',
              });

              this.eventhalls[11].push({
                resourceId: 11,
                start: `${this.$moment(this.$data.viewDate).format('YYYY-MM-DD')} 09:00:00`,
                end: `${this.$moment(this.$data.viewDate).format('YYYY-MM-DD')} 21:00:00`,
                className: 'r-impossible',
              });

            } else {
              this.eventhalls[v.placeId].push({
                resourceId: v.placeId,
                start: `${this.$moment(this.$data.viewDate).format('YYYY-MM-DD')} 09:00:00`,
                end: `${this.$moment(this.$data.viewDate).format('YYYY-MM-DD')} 21:00:00`,
                className: 'r-impossible',
              });
            }
          });
          this.events = [
            ...this.eventhalls[6],
            ...this.eventhalls[7],
            ...this.eventhalls[8],
            ...this.eventhalls[9],
            ...this.eventhalls[10],
            ...this.eventhalls[11],
          ];

          this.getList();
        }
      });
    },
    onChange(newViewDate) {
      this.viewDate = newViewDate;
      console.log(newViewDate);
      this.isWeekend = ([0, 6].indexOf(newViewDate.getDay()) !== -1);
    },
    openCalendar() {
      this.$refs.calendar.showCalendar();
    },
    onSelect(Info, Calendar) {
      const startDate = this.$moment(Info.start);
      const endDate = this.$moment(Info.end);
      const minute = endDate.diff(startDate, 'minute');
      const calApi = Calendar.getApi();
      const isReservation = (type, start, end) => {
        let invalid = false;

        const weekday = this.$moment(start).isoWeekday()
        var s;
        if (this.$moment(start).hours() == 9 && (weekday == 6 || weekday == 7)) {
          // 주말이고 9:30분이면 30분 추가 안함.
          s = this.$moment(start)
        } else {
          s = this.$moment(start).add(-30, 'm')
        }
        
        const e = this.$moment(end).add(30, 'm')
        return this.eventhalls[type].some((v) => {
          if (v.uuid) {
            invalid = s.isBefore(this.$moment(v.end).add(30, 'm')) && e.isAfter(this.$moment(v.start).add(-30, 'm'))
            return invalid;
          } else {
            invalid = s.isBefore(v.end) && e.isAfter(v.start);
            return invalid;
          }
        });
      };

      if (minute === 30) {
        calApi.unselect();
        return;
      }
      if (minute < 120 || minute % 60 !== 0) {
        this.showAlert(this.$t('alertMessage.invalidVenueHour'));
        calApi.unselect();
        return;
      }

      if (!isReservation(Info.resource.id, startDate, endDate)) {
        const uuid = `__ids__${this.selectedHalls.length}`;
        const item = {
          uuid,
          resourceId: Info.resource.id,
          start: Info.startStr,
          end: Info.endStr,
          className: 'r-select',
        };
        this.selectedHalls.push(item);
        this.events.push(item);
        this.eventhalls[Info.resource.id].push({
          ...item,
          className: 'disabled-date',
        });
      } else {
        // this.showAlert('메인홀 예약 시간 전후 30분<br/>정리시간이 필요합니다.');
        this.showAlert(this.$t('alertMessage.invalidEventHallErr1'))
      }
      calApi.unselect();
    },
    /**
     * https://fullcalendar.io/docs/eventRender
     */
    onRender(Info) {
      const { event, el } = Info;
      
      if (event.extendedProps.uuid) {
        const closeNode = document.createElement('span');
        closeNode.innerText = 'X';
        closeNode.addEventListener('click', () => {
          let find;
          this.events.some((v, i) => {
            const bool = v.uuid === event.extendedProps.uuid;
            if (bool) {
              find = i;
            }
            return bool;
          });
          this.events.splice(find, 1);
          this.eventhalls[event.getResources()[0].id].some((v, i) => {
            const bool = v.uuid === event.extendedProps.uuid;
            if (bool) {
              find = i;
            }
            return bool;
          });
          this.eventhalls[event.getResources()[0].id].splice(find, 1);

          this.selectedHalls.some((v, i) => {
            const bool = v.uuid === event.extendedProps.uuid;
            if (bool) {
              find = i;
            }
            return bool;
          });
          this.selectedHalls.splice(find, 1);
        });
        el.append(closeNode);
      } else {
        // 대관신청 s
        // eslint-disable-next-line no-unused-expressions
        // console.log(event.extendedProps.placeId, event.extendedProps.status)
        // event.extendedProps.placeId === 6 && (() => { // 이벤트홀
        //   if (event.extendedProps.status === 9006) { // 대관 승인
        //     if (event.extendedProps.memberId === 0) {
        //       el.classList.add('request2');
        //     } else {
        //       el.classList.add('request');
        //     }
        //     if (el.find) {
        //       el.find('.fc-content span').text(event.extendedProps.origin);
        //     }
        //   }
        //   // 예약시간이 그래프에 처음왔을때는 모양이 달라져야 해서..
        //   // eslint-disable-next-line no-unused-expressions
        //   // eslint-disable-next-line radix
        //   if (parseInt(this.$moment(event.start).format('HHmm')) <= parseInt(this.fullCalendarConfig.minTime.split(':')
        //     // eslint-disable-next-line no-unused-expressions
        //     .reduce((all, v, i) => { (i < 2 && (all += v)); return all; }, ''))) {
        //     el.classList.add('line-over');
        //   }
        // })();
        // eslint-disable-next-line no-unused-expressions

        // 내가 신청한 건만 색 변경
        // this.userInfo.id === event.extendedProps.memberId && (() => {
        //   console.log('event.extendedProps', event.extendedProps.status)
        //   if (event.extendedProps.status === 9006) {
        //     // 대관 승인
        //     el.classList.remove('r-impossible');
        //     el.classList.add('r-confirm');
        //   } else if (event.extendedProps.status === 9005) {
        //     // 대관 신청
        //     el.classList.remove('r-impossible');
        //     el.classList.add('r-waiting');
        //   }
        // })();

        // 모든 신청건에 대한 색 변경
        if (event.extendedProps.status === 9006) {
          // 대관 승인
          el.classList.remove('r-impossible');
          el.classList.add('r-confirm');
        } else if (event.extendedProps.status === 9005) {
          // 대관 신청
          el.classList.remove('r-impossible');
          el.classList.add('r-waiting');
        }

        if (event.extendedProps.status === 9007 || (this.userInfo.memberId !== event.memberId && event.status === 9005)) { // 대관 신청일 경우 사용자 ID체크
          //el.css('display', 'none');
          el.style.display = 'none';
        }
      }
    },
  },
};


</script>

<style>
@import '~@fullcalendar/core/main.min.css';
@import '~@fullcalendar/daygrid/main.min.css';
@import '~@fullcalendar/timegrid/main.min.css';

.fc-event-container .fc-title {font-size:.85em;}
.fc-event-container .fc-not-start .fc-content {margin-top: 0px!important;}
.fc-event-container .fc-not-end .fc-content {margin-bottom: 0px!important;}
.fc-event-container .fc-time-grid-event.r-impossible .fc-time,
.fc-event-container .fc-time-grid-event.r-impossible .fc-title {display:none;}
.fc-event-container .fc-time-grid-event.r-impossible {z-index: -1 !important;}

.fc-time-grid tr td .fc-time-grid-event:not(.r-select):not(.r-impossible) .fc-time {display:none;}
.fc-time-grid tr td .fc-time-grid-event:not(.r-select):not(.r-impossible) .fc-title {display:block !important;}
.fc-time-grid tr td .fc-event.r-waiting {background:rgba(151, 200, 236, 0.2) !important}
.fc-time-grid tr td .fc-time-grid-event.r-waiting:not(.r-select):not(.r-impossible) .fc-content {
  width:100%;margin:20px -19px;padding:10px 20px;opacity:1;background:rgba(151, 200, 236, 0.9) !important;}
.fc-time-grid tr td .fc-event.r-confirm {background:rgba(49, 144, 214, 0.2) !important}
.fc-time-grid tr td .fc-time-grid-event.r-confirm:not(.r-select):not(.r-impossible) .fc-content {
  width:100%;margin:20px -19px;padding:10px 20px;opacity:1;background:rgba(49, 144, 214, 0.9) !important;}

.v-hidden {visibility: hidden;width:0px!important}
#contents .vdp-datepicker__calendar .cell:not(.day-header):not(.month):not(.year):after{ top: -4px;}
</style>
